module.exports = {
  amplitudePublicKey: "50069f36dd148b621a96f4cbe2147a46",
  googleTrackingId: "GTM-PBFFFT6",
  uploadCarePublicKey: "90dfbb72ce3aba4a6514",
  stripePublicKey: "pk_live_NatdO3YfNE3EY3Z2XkVyEJFB00MNlZ0fb7",
  mlServerHost: "https://chrisfifty.com:8080",
  // apiUrl: "http://localhost:5001/imagineart-f3126/us-central1",
  apiUrl: "https://us-central1-imagineart-f3126.cloudfunctions.net",
  resolution: 250000,
};
