import { init } from "rematch-core-pjamrozowicz";
import immerPlugin from "rematch-immer-pjamrozowicz";
import loadingPlugin from "rematch-loading-pjamrozowicz";
import * as models from "./models";

const createStore = () =>
  init({
    models,
    plugins: [loadingPlugin(), immerPlugin()],
  });

export default createStore;
