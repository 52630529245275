/**
 * @fileoverview gRPC-Web generated client stub for 
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');

const proto = require('./style-transfer_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.StyleTransferClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.StyleTransferPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.StylizeMessage,
 *   !proto.StylizeResponse>}
 */
const methodDescriptor_StyleTransfer_StreamStylize = new grpc.web.MethodDescriptor(
  '/StyleTransfer/StreamStylize',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.StylizeMessage,
  proto.StylizeResponse,
  /**
   * @param {!proto.StylizeMessage} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.StylizeResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.StylizeMessage,
 *   !proto.StylizeResponse>}
 */
const methodInfo_StyleTransfer_StreamStylize = new grpc.web.AbstractClientBase.MethodInfo(
  proto.StylizeResponse,
  /**
   * @param {!proto.StylizeMessage} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.StylizeResponse.deserializeBinary
);


/**
 * @param {!proto.StylizeMessage} request The request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.StylizeResponse>}
 *     The XHR Node Readable Stream
 */
proto.StyleTransferClient.prototype.streamStylize =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/StyleTransfer/StreamStylize',
      request,
      metadata || {},
      methodDescriptor_StyleTransfer_StreamStylize);
};


/**
 * @param {!proto.StylizeMessage} request The request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.StylizeResponse>}
 *     The XHR Node Readable Stream
 */
proto.StyleTransferPromiseClient.prototype.streamStylize =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/StyleTransfer/StreamStylize',
      request,
      metadata || {},
      methodDescriptor_StyleTransfer_StreamStylize);
};


module.exports = proto;

