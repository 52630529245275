const initialState = {
  items: [],
};

const cart = {
  state: initialState,
  reducers: {
    addToCart(state, item) {
      state.items.push(item);
      return state;
    },
  },
};

export default cart;

export const addToCart = d => d.cart.addToCart;

export const getCart = s => s.cart.items;
