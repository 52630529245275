import { createMuiTheme } from "@material-ui/core/styles";

const theme = createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 479,
      md: 767,
      lg: 991,
      xl: 1100,
    },
  },
  palette: {
    primary: {
      main: "#000a2a",
    },
    secondary: {
      main: "#ebbe02",
    },
  },
  typography: {
    fontFamily: "Work Sans, Roboto, Helvetica, Arial, sans-serif",
  },
});

export default theme;
