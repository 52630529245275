import ky from "ky";
import config from "../../config";

const { StyleTransferClient } = require("../api/style-transfer_grpc_web_pb");
const { StylizeMessage } = require("../api/style-transfer_pb");

const styleServer = new StyleTransferClient(config.mlServerHost);

/**
 * @param imageUrl
 * @param artStyle
 * @param artUrl
 * @return {!grpc.web.ClientReadableStream<!proto.StylizeResponse>}
 */
const stylizeImage = (imageUrl, artStyle, artUrl) => {
  const stylizeMessage = new StylizeMessage();
  stylizeMessage.setContentPath(imageUrl);
  stylizeMessage.setPresetStyle(artStyle);
  stylizeMessage.setStylePath(artUrl);

  return styleServer.streamStylize(stylizeMessage);
};

const getCanvasSizes = (inputWidth, inputHeight, styleWidth, styleHeight) => {
  return ky.post(`${config.apiUrl}/getCanvasSizes`, {
    json: {
      inputWidth,
      inputHeight,
      styleWidth,
      styleHeight
    }
  }).json();
};

const createCheckout = ({ canvasId, contentUrl, presetStyle, styleUrl, email, address, firstName, lastName, promoCode, isVertical }) => {
  return ky.post(`${config.apiUrl}/createCheckout`, {
    json: {
      canvasId,
      contentUrl,
      presetStyle,
      styleUrl,
      email,
      address,
      firstName,
      lastName,
      promoCode,
      isVertical
    }
  }).json();
};

const getPromoCode = (code) => {
  return ky.post(`${config.apiUrl}/validatePromoCode`, {
    searchParams: { code }
  }).json();
};

export default {
  stylizeImage,
  getCanvasSizes,
  createCheckout,
  getPromoCode
};
