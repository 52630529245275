/* eslint-disable no-use-before-define */
import _ from "lodash";
import api from "../api";

const {
  StylizeMessage: { Style },
} = require("../api/style-transfer_grpc_web_pb");

const initialState = {
  error: null,
  uploadedImage: null,
  uploadedStyle: null,
  artStyle: Style.NONE,
  canvasSizes: null,
  orderVisible: false,
  lastUsedStyle: null,
  stylizedUrl: null,
};

const data = {
  state: initialState,
  reducers: {
    setError(state, error) {
      state.error = error;
      return state;
    },
    setImage(state, image) {
      state.uploadedImage = image;
      state.canvasSizes = null;
      state.lastUsedStyle = null;
      state.stylizedUrl = null;
      return state;
    },
    setArtStyle(state, style) {
      state.artStyle = style;
      state.uploadedStyle = null;
      state.canvasSizes = null;
      return state;
    },
    setUploadedStyle(state, image) {
      state.uploadedStyle = image;
      state.artStyle = Style.NONE;
      state.canvasSizes = null;
      return state;
    },
    toggleOrderPage(state) {
      state.orderVisible = !state.orderVisible;
      return state;
    },
    setCanvasSizes(state, sizes) {
      state.canvasSizes = sizes;
      return state;
    },
    setLastUsedStyle(state) {
      state.lastUsedStyle = state.uploadedStyle
        ? state.uploadedStyle.url
        : state.artStyle;
      return state;
    },
    setStylizedUrl(state, url) {
      state.stylizedUrl = url;
      return state;
    },
  },
  effects: {
    async fetchCanvasSizes(payload, rootState) {
      const input = getUploadedImage(rootState);
      const style = getUploadedStyle(rootState);

      try {
        const sizes = await api.getCanvasSizes(
          input.originalWidth,
          input.originalHeight,
          _.get(style, "originalWidth"),
          _.get(style, "originalHeight")
        );

        this.setCanvasSizes(sizes);
        this.toggleOrderPage();
      } catch (err) {
        window.alert(
          "Something went wrong and we are working on it! Please try again later."
        );
        console.error(err);
      }
    },
  },
};

export default data;

export const setError = d => d.data.setError;
export const setImage = d => d.data.setImage;
export const setUploadedStyle = d => d.data.setUploadedStyle;
export const setArtStyle = d => d.data.setArtStyle;
export const setCanvasSizes = d => d.data.setCanvasSizes;
export const toggleOrderPage = d => d.data.toggleOrderPage;
export const fetchCanvasSizes = d => d.data.fetchCanvasSizes;
export const setLastUsedStyle = d => d.data.setLastUsedStyle;
export const setStylizedUrl = d => d.data.setStylizedUrl;

export const getError = s => s.data.error;
export const getUploadedImage = s => s.data.uploadedImage;
export const getUploadedStyle = s => s.data.uploadedStyle;
export const getArtStyle = s => s.data.artStyle;
export const getCanvasSizes = s => s.data.canvasSizes;
export const getLastUsedStyle = s => s.data.lastUsedStyle;
export const getStylizedUrl = s => s.data.stylizedUrl;
export const isOrderVisible = s => s.data.orderVisible;
export const isLoadingCanvasSizes = s =>
  s.loading.effects.data.fetchCanvasSizes;
